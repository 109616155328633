<template>
  <div >
    <!-- <index-view></index-view> -->
   <!-- <his-view></his-view> -->
   <!-- <page-view-1></page-view-1> -->
   <!-- <page-view-2></page-view-2> -->
    <router-view></router-view>
  </div>
</template>

<style>

</style>
<script>
// import PageView1 from './views/page/PageView1.vue'
// import PageView2 from './views/page/PageView2.vue'
// import IndexView from './views/login/IndexView.vue';
// import HisView from './views/page/HisView.vue';
export default {
//  components: { PageView2 },
  data() {
    return {
      
    }
  }
}
</script>
